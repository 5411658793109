define("wavelengthspace/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gZa1DOEz",
    "block": "[[[1,[28,[35,0],[\"Wavelength Space\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"container-fluid index-con\"],[12],[1,\"\\n\\n\\t\"],[10,0],[14,0,\"row justify-content-center exhibition\"],[12],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"col-12 col-xl-4 text-center\"],[12],[1,\"\\t\\n\\t\\t\\t\"],[10,\"img\"],[14,\"src\",\"assets/images/AsphaultCarpet.png\"],[14,\"alt\",\"Asphalt Carpet\"],[14,0,\"img-fluid mt-3\"],[12],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\\n\\t\"],[10,0],[14,0,\"row justify-content-center mt-3\"],[12],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"col-12 col-xl-8\"],[12],[1,\"\\n\\n\\t\\t\\t\"],[10,\"h1\"],[12],[1,\"Asphalt Carpet\"],[13],[1,\"\\n\\n\\t\\t\\t\"],[10,2],[12],[1,\"This August, Wavelength Space is presenting a solo show of sculpture and a stop-motion film installation by Memphis based artist, Kelsey Harrison. In her solo show, Kelsey examines American car culture via a stop-motion universe and through discreet sculptural encounters. In her imagined reality, Kelsey illuminates the realm of road culture as a space of possibility, desire, and freedom while simultaneously posing questions about access and identity.\"],[13],[1,\"\\n\\n\\t\\t\\t\"],[10,2],[12],[1,\"Opening Reception is Friday, August 2nd from 6-9pm\"],[13],[1,\"\\n\\t\\t\\t\"],[10,2],[12],[1,\"Closing Reception and artist talk date TBD\"],[13],[1,\"\\n\\t\\t\\t\"],[10,2],[12],[1,\"The gallery will be available by appointment through September 1st\"],[13],[1,\"\\n\\n\\t\\t\\t\"],[10,2],[12],[10,\"i\"],[12],[1,\"This exhibition contains Adult Content. Gallery visitors must be 18 years of age or older.\"],[13],[13],[1,\"\\n\\n\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\\n\"],[13],[1,\"\\n\\n\\n\"],[46,[28,[37,2],null,null],null,null,null]],[],false,[\"page-title\",\"component\",\"-outlet\"]]",
    "moduleName": "wavelengthspace/templates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});